import { Switch, Route } from 'react-router-dom';
import {  ADMIN, ROOT } from 'constants/RoutePaths';
import { Login } from 'pages/login/Login';
import { Admin } from 'pages/admin/Admin';


export const AppRouter = () => {
    return (
        <Switch>
            {/* List all public routes here */}
            <Route exact path={ROOT} component={Login} />

            <Route path="/login">
                <Login />
            </Route>

            <Route path={ADMIN}>
                <Admin />
            </Route>

            {/* List all private/auth routes here */}
            {/* <PrivateRoute path={AUTH_PAGE1}>
                <AuthorizedPage1 />
            </PrivateRoute> */}

            {/* List a generic 404-Not Found route here */}
            {/* <Route path="*">
                <NotFound />
            </Route> */}
        </Switch>
    );
};