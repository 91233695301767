import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";

import "assets/css/main.css";
import "assets/fonts/icomoon/icomoon.css";
import axios from "axios";
import { store } from "redux/store/index";
// require('dotenv').config();
//Axios Base Url
// axios.defaults.baseURL = "http://139.59.24.214/spare-hands-api";
console.log("URL = ", process.env.REACT_APP_BASE_URL);
// console.log("name ", process.env.REACT_APP_BASE_NAME);
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL; 

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
