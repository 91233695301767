import { put, takeLatest } from 'redux-saga/effects';
import { 
    GET_SERVICE_PROVIDERS, 
    GET_SERVICE_PROVIDERS_ERROR, 
    GET_SERVICE_PROVIDERS_SUCCESS } from 'redux/actions';
// import API from './Api';

function* fetchServiceProviders(){
    try{
        // let user: any = yield call(API.getApiResponse('/app/user/list-user'));
        // console.log("users ", user);
        
        yield put({type: GET_SERVICE_PROVIDERS_SUCCESS});
    }catch (e){
        yield put({type: GET_SERVICE_PROVIDERS_ERROR, message: e.message});
    }
}



function* appSaga(){
    yield takeLatest(GET_SERVICE_PROVIDERS, fetchServiceProviders);
}

export default appSaga;