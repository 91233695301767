import { GET_SERVICE_PROVIDERS, TOGGLE_SIDE_MENU } from '../actions';

const initialState = {
  sideMenu: false,
  serviceProviders: []
};

const rootReducer = (state = initialState, action: any) => {
    switch(action.type){
        case TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenu: !state.sideMenu
            };
        case GET_SERVICE_PROVIDERS:
            return {
                ...state,
                serviceProviders: state.serviceProviders
            }
        default:
            return state;
    }
}

export default rootReducer;