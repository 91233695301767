import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from "../reducers/index";
// import devToolsEnhancer from 'remote-redux-devtools';
import appSaga from '../sagas/index';


// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// then run the saga
sagaMiddleware.run(appSaga);