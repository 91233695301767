import axios from "axios";

export const getUser = () => {
    const userStr = localStorage.getItem("userInfo");
    if(userStr) {
        return JSON.parse(userStr);
    }else {
        return null;
    }
}

export const getToken = () => {
    return localStorage.getItem("token") || null;
}

export const setUserSession = (token: any, userInfo: any) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

    localStorage.setItem("token", token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
}

export const removeUserSession = () =>{
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userInfo");

    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
}

export const getPostData = (url: any, data: any) => {
    const options = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    };
    return axios.post(url, data, options);
}





export const getPutData = (url: any, data: any) => {
    const options = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    };
    return axios.put(url, data, options);
}

export const getApiData = (url: any) => {
    const options = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    };
    return axios.get(url, options);
}

export const deleteData = (url: any) => {
    const options = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    };
    return axios.delete(url, options);
}