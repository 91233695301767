import React, { useEffect, useState } from "react";
import './ServiceProviderDeatils.css';
import { Link } from "react-router-dom";
import { getApiData, getPutData } from "helpers/CommonUtils";
import { toast } from 'react-toastify';
import { ADMIN_DASHBOARD, ADMIN_SERVICE_DETAILS, ADMIN_SERVICE_PROVIDERS } from "constants/RoutePaths";
import defaultUserImage from '../../../assets/images/default_user.jpg';
import { Modal, Button } from 'react-bootstrap';


export const ServiceProviderDeatils = (props: any) => {
	const [serviceList, setServiceList] = useState<any[]>([]);
	const [serviceProvider, setServiceProvider] = useState<any>({});
	const [refresh, setRefresh] = useState(false);
	const [viewMode, setViewMode] = useState('list');
	const [open, setOpen] = useState(false);
	const [field, setField]=useState({
		id:"",
		activity: 0,
		note:""
	});

	useEffect(() =>{
		getApiData(`/app/portfolio/userPortfolio/${props.match.params.id}/2`)
		.then((response) => {
			setServiceList(response.data.data[0].services);
			setServiceProvider(response.data.data[0]);
		})
		.catch((error) => {
			console.log(error)
		});
		;
	},[refresh]);
	 
	
	const openModal = (item: any) => { 
		
	   	setField({...field, id:item.id, activity:item.activity === 1 ? 2 : 1,note: item.note})
		setOpen(!open);
	};
	
	const onCloseModal = () => {
		
		setOpen(false);
	};

	const handleOnChange = async () => {
		getPutData('app/common/protfolioActivity', field)
		.then((response) => {
		  if(response.data.status === 1){
		  toast.success(response.data.message, {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  });
			setOpen(false);
		  }else{
		  toast.error(response.data.message, {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  });
			  setOpen(false);
		  } 
	  	});
		
	  setField({...field, id:'', activity: 0,note: ''});
		setRefresh(!refresh);
	};

    return (
		<React.Fragment>
			<header className="main-heading">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
							<div className="page-icon">
							<i className="icon-cogs"></i>
							</div>
							<div className="page-title">
								<h5>User Services</h5>
								<h6 className="sub-heading"></h6>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={ADMIN_DASHBOARD}>Dashboard</Link></li>
									<li className="breadcrumb-item"><Link to={ADMIN_SERVICE_PROVIDERS}>Service Providers</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Service Provider Details</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</header>

			<div className="main-content">
				<div className="row">
					<aside className="col-md-12">
						<div className="card">
							<div className="card-header">
								Profile Details
							</div>
							<div className="card-body">
								<div className="row h-100 justify-content-center align-items-center">
									<div className="col-md-2 user-profile">
										{ serviceProvider.image != null ? 
											<img src={`${process.env.REACT_APP_BASE_URL}${serviceProvider.image}`} className="img-fluid blog-thumb" alt="User Image" /> : 
											<img src={defaultUserImage} className="img-fluid blog-thumb" alt="User Image" />
										}
									</div>
									<div className="col-md-10">
										<ul className="profile-ul">
											<li><span className="icon-user"></span> {serviceProvider.first_name} {serviceProvider.last_name}</li>
											<li><span className="icon-drafts"></span> {serviceProvider.email}</li>
											<li><span className="icon-stay_primary_portrait"></span> +91 - {serviceProvider.mobile}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
				<div className="card">
					<div className="card-header">
						<aside className="row h-100 justify-content-center align-items-center">
							<div className="col-md-8">
								Service details
							</div>
							<div className="col-md-4">
								<ul className="grid-ul">
									<li>
										<a href="javascript:void(0)" className={viewMode == 'list' ? 'list-view acive' : 'list-view'} onClick={() => setViewMode('list')}>t
											<span className="icon-th-list"></span>
										</a>
									</li>
									<li>
										<a href="javascript:void(0)" className={viewMode == 'grid' ? 'grid-view active' : 'grid-view'} onClick={() => setViewMode('grid')}>
											<span className="icon-th-large"></span>
										</a>
									</li>
								</ul>
							</div>
						</aside>
					</div>
				</div>
				<div className="row">
				{serviceList.map((item, index) => (
				<div className={viewMode == 'list' ? 'col-md-12' : 'col-md-12 grid'} key={index}>
					<div className="card">
					<div className="card-body">
						<div className="row">
							<aside className="col-md-3 full-width">
								{ item.image === null ? 
									<img src={`${process.env.REACT_APP_BASE_URL}app/uploads/portfolio/service_image.jpg`}
										className="serviceImage" 
										style={ {height: '50px', width: '70px'} }
										alt="Service Image" /> : 
									<img src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
										className="serviceImage" style={ {height: '50px', width: '70px'} }
										alt="Service Image" />
								}
								{/* <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
									<ol className="carousel-indicators">
										<li data-target="#carouselExampleCaptions" data-slide-to="0" className=""></li>
										<li data-target="#carouselExampleCaptions" data-slide-to="1" className="active"></li>
										<li data-target="#carouselExampleCaptions" data-slide-to="2" className=""></li>
									</ol>
									<div className="carousel-inner">
										<div className="carousel-item">
											<img className="d-block w-100" src="img/login-slider.jpg" alt="First slide" />
										</div>
										<div className="carousel-item active">
											<img className="d-block w-100" src="img/login-slider.jpg" alt="First slide" />
										</div>
										<div className="carousel-item">
											<img className="d-block w-100" src="img/login-slider.jpg" alt="First slide" />
										</div>
									</div>
									<a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="sr-only">Previous</span>
									</a>
									<a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="sr-only">Next</span>
									</a>
								</div> */}
							</aside>
							<aside className="col-md-5 full-width">
								<div className="detail-box">
									<h4>{item.title}</h4>
									<p>{item.description}</p>
								</div>
							</aside>
							<aside className="col-md-4 full-width">
								<div className="right-detailbox">
									<div className="right-top-section">
										<table className="table-responsive">
											<tbody>
												<tr>
													<td>Price:</td>
													<td><span className="rupees-symbol">&#8377;</span> {item.price}</td>
												</tr>
												<tr>
													<td>Category:</td>
													<td>{item.category_name}</td>
												</tr>
												<tr>
													<td>Ratings:</td>
													<td>{}</td>
												</tr>
												{/* <tr>
													<td>Review:</td>
													<td className="review-nochecked">4.8 
														<span className="fa fa-star checked"></span>
														<span className="fa fa-star checked"></span>
														<span className="fa fa-star checked"></span>
														<span className="fa fa-star checked"></span>
														<span className="fa fa-star"></span>
													</td>
												</tr> */}
											</tbody>
										</table>
									</div>
									<div className="location-ico">
										{item?.locations.length > 0 && item?.locations.map((location) => (
											location.location.map((area) => (
												<i className="icon-location">{area.value} </i> 
											))
										))}
									</div>
									{/* <div className="row open-today">
										<div className="col-md-6">
											<span className="icon-clock"></span> Open Today: 
											<p>9:00 AM to 7:00 PM</p>
										</div>
										<div className="col-md-6">
											<span className="icon-calender-outline"></span> Special Hours: 
											<p>25-05-2021 Closed</p>
										</div>
									</div> */}
									<div className="row open-today h-100 justify-content-center align-items-center">
										<div className="col-md-6">
											{/* <label className="switch">
												<input type="checkbox" checked />
												<span className="slider round"></span>
											</label> */}
											<div className="toggle-switch">
												<input
													type="checkbox"
													id={`custom-checkbox-${index}`}
													onChange={() => openModal(item)}
													checked={item.activity === 2 ? true : false}
													className="check"
												/>
												<b className="b switch"></b>
												<b
												className={item.activity == 2 ? "b track" : "after"}
												></b>
											</div>
										</div>
										<div className="col-md-6 edit-service">
											<Link className="btn" to={`${ADMIN_SERVICE_DETAILS}/${item.id}`}>
												<i className="icon-eye-outline"></i> View this service
											</Link>
										</div>
									</div>
								</div>
							</aside>
						</div>
					</div>
					</div>
				</div>
				))}
</div>
			</div>
			{/* Open Modal for Block/Un-Block */}
			<Modal show={open} onHide={onCloseModal} backdrop="static"
				keyboard={false} aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
				<Modal.Title>Block / Unblock Service Provider</Modal.Title>
				</Modal.Header>

				<Modal.Body>
				<p>Please Write a note</p>
				<textarea rows={5} cols={55} 
					value={field.note} 
					onChange={(e)=>setField({...field,note:e.target.value})}>
				</textarea>
				</Modal.Body>

				<Modal.Footer>
				<Button variant="secondary" onClick={() => onCloseModal()}>Cancel</Button>
				<Button variant="primary" onClick={handleOnChange}>Update</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
    );
};