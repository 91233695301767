// import React from 'react';

export default function Footer() {
    return (
    <footer className="main-footer no-bdr fixed-btm">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    COPYRIGHTS © { new Date().getFullYear() } 12400073 Canada Inc. ALL RIGHTS RESERVED
                </div>
                <div className="col-md-6 text-right">
                    Powered by <a href="http://transformbizsolutions.com/">Transform Biz Solutions</a>
                </div>
            </div>
        </div>
    </footer>
    );
};