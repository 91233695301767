import './App.css';
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from 'routes/AppRouters';
// require('dotenv').config();
const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
