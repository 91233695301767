import React from "react";
import { CChart } from '@coreui/react-chartjs'
import './Dashboard.css'
export const Dashboard = (props: { consumerData: any[], serviceData: any[], playStoreData: any[], categoryData: any[] }) => {
  const { consumerData } = props;
  const { serviceData } = props;
  const { playStoreData } = props;
  const { categoryData } = props;
  console.log("Latest: ", playStoreData['maxInstalls'])
  return (
    <React.Fragment>
      <header className="main-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div className="page-icon">
                <i className="icon-laptop_windows"></i>
              </div>
              <div className="page-title">
                <h5>Dashboard</h5>
                <h6 className="sub-heading">Welcome to Spare Hands</h6>
              </div>
            </div>

          </div>
        </div>
      </header>
      <div className="main-content">
        <span>Dashboard</span>

        <div className="row my-4">
          <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="card">
              <h5 className="card-header card-header1">Total consumers</h5>
              <div className="card-body">
                <h5 className="card-title">{consumerData.length}</h5>
                {/* <p className="card-text">Feb 1 - Apr 1, United States</p>
              <p className="card-text text-success">
                18.2% increase since last month
              </p> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-4">
            <div className="card">
              <h5 className="card-header card-header2">Total No Of Servie Provider</h5>
              <div className="card-body">
                <h5 className="card-title">{serviceData.length}</h5>
                {/* <p className="card-text">Feb 1 - Apr 1, United States</p>
              <p className="card-text text-success">
                4.6% increase since last month
              </p> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-4">
            <div className="card">
              <h5 className="card-header card-header3">App Download Count</h5>
              <div className="card-body">
                <h5 className="card-title">{playStoreData['maxInstalls']}</h5>
                {/* <p className="card-text">Feb 1 - Apr 1, United States</p>
              <p className="card-text text-danger">
                2.6% decrease since last month
              </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6 mb-4 mb-lg-0">
            <div className="card">
              <h5 className="card-header">Compare Chart</h5>
              <div className="card-body card-body1">
                <CChart
                  type="bar"
                  data={{
                    labels: ['Consumer', 'Service Provider', 'Download'],
                    datasets: [
                      {
                        label: 'Spare Hands',
                        backgroundColor: '#f87979',
                        data: [consumerData.length, serviceData.length, playStoreData['maxInstalls']],
                      },
                    ],
                  }}
                />
              </div>

            </div>
          </div>
          <div className="col-12 col-xl-6">
            <div className="card">
              <h5 className="card-header">Category</h5>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>

                    </thead>
                    <tbody>
                      {categoryData.map((item, index) => (
                        <tr>
                          <td className="card-header"><h5 className="mb-0">
                            <a
                              data-toggle="collapse"
                              // href="#collapseFour"
                              className="collapsed"
                              aria-expanded="true"
                              aria-controls="collapseFour"
                            // onClick={() => toggleExpand ? setToggleExpand(true) : setToggleExpand(false)}
                            >
                              <span>{item.name}</span>
                            </a></h5></td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
                {/* <a href="javascript:void(0)" className="btn btn-block btn-light">
                  View all
                </a> */}
              </div>
            </div>


          </div>
        </div>


      </div>




    </React.Fragment>
  );
};