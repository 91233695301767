import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getApiData,
  getPostData,
  getPutData,
  deleteData,
} from "helpers/CommonUtils";
import { toast } from "react-toastify";
// import Select from 'react-select';

import "./Category.css";
import { ADMIN_DASHBOARD } from "constants/RoutePaths";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
interface IFormInput {
  name: string;
  parent_id: number;
}

export const Category = () => {
  // Get Category Data
  const [category, setCategory] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [editForm, setEditForm] = useState(true);
  const [editCategory, setEditCategory] = useState<any>();
  const [field, setField] = useState({
    id: "",
    isPopularity: 0,
    name: ""
  });

  const [fieldcategory, setFieldcategory] = useState();
  const [fieldsub, setFieldsub] = useState();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [openCate, setOpenCate] = useState(false);
  const [openSub, setOpenSub] = useState(false);

  useEffect(() => {
    getApiData("/app/category/list-category")
      .then((response) => {
        setCategory(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  const onSubmit = (catData: IFormInput) => {
    getPostData("/app/category/create-category", catData).then((response) => {
      if (response.data.success === true) {
        // resetForm();
        setRefresh(!refresh);
        toast.success("Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Creation Failed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // Update Data *For both Category & Sub-Category
  const saveCategory = () => {
    setEditForm(true);
    console.log("editCategory", editCategory);
    getPutData("/app/category/update-category", editCategory).then(
      (response) => {
        if (response.data.success === true) {
          setEditForm(true);
          setRefresh(!refresh);
          toast.success("Category Updated Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Category Update Failed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    );
  };
 
  const onHandleChange = (event: any) => {
    setEditCategory({
      ...editCategory,
      [event.target.name]: event.target.value,
    });
  };

  //Edit Category
  const onEdit = (index: any) => {
    console.log("onEdit", index);
    setEditCategory(category[index]);
  };

  // Edit Sub-Category
  const onEditSubCategory = (index: any, subIndex: any) => {
    setEditCategory(category[index].sub_category[subIndex]);
  };

  const openModal = (item: any) => {
    setField({
      id: item.id,
      isPopularity: item.isPopularity === 0 ? 1 : 0,
      name: item.name
    });
    setOpen(!open);
  };


  const onCloseModal = () => {
    setOpen(false);
    setField({
      id: "",
      isPopularity: 0,
      name: ""
    });
  };

  const openModalDelete = (index: any) => {
    setFieldcategory(index);
    setOpenCate(!open);
  };

  const onCloseModalDelete = () => {
    setOpenCate(false);
   };

   const openModalDeleteSubCategory = (index: any, subIndex: any) => {
    setFieldcategory(index);
    setFieldsub(subIndex);
    setOpenSub(!open);
  };
 
  const onCloseModalDeleteSub = () => {
    setOpenSub(false);
   };
  

  const cancelUpdateCategory = () => {
    setEditForm(true);
    toast.warn("You didn't changed anything!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  // Delete Category
  const onDeleteCategory = (index: any) => {
    setOpenCate(false);
    deleteData(`app/category/delete-category/${category[index].id}`).then(
      (response) => {
        if (response.data.success === true) {
          setRefresh(!refresh);
          toast.success("Category Deleted Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Category Delete Failed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    );
  };
  

  //Delete Sub-Category
  const onDeleteSubCategory = (index: any, subIndex: any) => {
    setOpenSub(false);
    deleteData(
      `app/category/delete-category/${category[index].sub_category[subIndex].id}`
    ).then((response) => {
      if (response.data.success === true) {
        setRefresh(!refresh);
        toast.success("Category Deleted Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Category Delete Failed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const handleOnChange = () => {
    console.log("handleOnChange ", field);
    getPutData("/app/category/setPopularity/", {
      isPopularity: field.isPopularity, id : field.id
    })
    .then((response) => {
      
      if(response.data.success == true) {
        console.log('response.data.success', response.data.success)
        toast.success("Category Changed Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        setOpen(false);
      } else {
        toast.error("Category Changed Failed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setOpen(false);
      }

      setField({
        id: "",
        isPopularity: 0,
        name: ""
      });
      setRefresh(!refresh);
    });
  }


  return (
    
    <React.Fragment>
      <header className="main-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div className="page-icon">
                <i className="icon-list2"></i>
              </div>
              <div className="page-title">
                <h5>Category</h5>
                <h6 className="sub-heading">Welcome to Spare Hands</h6>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={ADMIN_DASHBOARD}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Category
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="main-content">
        <div className="row gutters">
          <aside className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            {editForm ? (
              <div className="card">
                <div className="card-header">Add New Category</div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Category Name"
                        {...register("name", { required: true })}
                      />
                    </div>
                    {errors.categoryName &&
                      errors.categoryName.type === "required" && (
                        <span className="errorMsg" style={{ color: "red" }}>
                          * It is required.
                        </span>
                      )}
                    <div className="form-group">
                      <select
                        className="form-control form-control-lg"
                        {...register("parent_id")}
                      >
                        <option value="0">Select Parent Category</option>
                        {category.map((items) => (
                          <option value={items.id}>{items.name}</option>
                        ))}
                      </select>
                    </div>
                    {/* <Select
                                    // value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                  /> */}
                    <button className="btn btn-sm btn-primary" type="submit">
                      <i className="icon icon-add-to-list"></i>&nbsp; Add
                    </button>
                  </form>
                </div>
              </div>
            ) : null}
            {!editForm ? (
              <div className="card">
                <div className="card-header">Update Category</div>
                <div className="card-body">
                  <div className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="name"
                      onChange={onHandleChange}
                      placeholder="Category Name"
                      value={editCategory?.name}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control form-control-lg"
                      {...register("parent_id")}
                    >
                      <option value="0">Select Parent Category</option>
                      {category.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    className="btn btn-sm btn-danger"
                    type="submit"
                    onClick={() => cancelUpdateCategory()}
                  >
                    <i className="icon icon-cancel2"></i>
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-sm btn-primary"
                    type="submit"
                    onClick={saveCategory}
                  >
                    <i className="icon icon-save2"></i>
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </aside>
          <aside className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
            <div className="card">
              
              {/* {loading? <LoaderHtml /> : null} */}
              {category.map((item, index) => (
                <div
                  id="accordionIcons"
                  className="accordion-icons"
                  role="tablist"
                >
                  <div className="mb-0">
                    <div className="card-header" role="tab" id="headingFour">
                      <h5 className="mb-0">
                        <a
                          data-toggle="collapse"
                         // href="#collapseFour"
                          className="collapsed"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                          // onClick={() => toggleExpand ? setToggleExpand(true) : setToggleExpand(false)}
                        >
                          <span>{item.name}</span>
                        </a>
                      
                        <ul className="category-action">
                          
                          <li className="toggle-switch" data-bs-toggle="tooltip" title={item.isPopularity === 1 ? 'Remove Popularity' : 'Make Popularity'}>
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              onChange={() =>
                              openModal(item)}
                              checked={item.isPopularity === 1 ? true : false}
                              className="check"
                            />
                            <b className="b switch"></b>
                            <b
                              className={
                                item.isPopularity == 1 ? "b track" : "after"
                              }
                              
                            ></b>
                          </li>
                          
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                onEdit(index);
                                setEditForm(!editForm);
                              }}
                              className="ico-edit"
                            >
                              <span className="icon-pencil3"></span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => openModalDelete(index)}
                              className="ico-delete"
                            >
                              <span className="icon-trash"></span>
                            </a>
                          </li>
                        </ul>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      // className={
                      //   toggleExpand === true ? "collapse show" : "collapse"
                      // }
                      role="tabpanel"
                      aria-labelledby="headingFour"
                      data-parent="#accordionIcons"
                    >
                      <div className="card-body">
                        {item.sub_category.map(
                          (subItem: any, subIndex: any) => (
                            <ul className="sub-categoryul">
                              <li>
                                <span>{subItem.name}</span>
                                <ul className="category-action">
                                {/* <li className="toggle-switch">
                                    <input
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      onChange={() => openModal(item)}
                                      checked={item.status === 2 ? true : false}
                                      className="check"
                                    />
                                    <b className="b switch"></b>
                                    <b
                                      className={
                                        item.status == 2 ? "b track" : "after"
                                      }
                                    ></b>
                                  </li> */}
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        onEditSubCategory(index, subIndex);
                                        setEditForm(!editForm);
                                      }}
                                      className="ico-edit"
                                    >
                                      <span className="icon-pencil3"></span>
                                    </a>
                                  </li>
                                  
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      className="ico-delete"
                                      onClick={() => openModalDeleteSubCategory(index, subIndex)
                                      }
                                    >
                                      
                                      <span className="icon-trash"></span>
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </aside>
        </div>
      </div>
      


      <Modal show={openCate} onHide={onCloseModalDelete} backdrop="static"
        keyboard={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title>
            Are you sure want to Delete category ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary"  onClick={() => onCloseModalDelete()}>No</Button>
          <Button variant="primary"  onClick={() => onDeleteCategory(fieldcategory)}>Yes</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={openSub} onHide={onCloseModalDeleteSub} backdrop="static"
        keyboard={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title>
            Are you sure want to Delete sub category ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary"  onClick={() => onCloseModalDeleteSub()}>No</Button>
          <Button variant="primary"  onClick={() => onDeleteSubCategory(fieldcategory,fieldsub)}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={open} onHide={onCloseModal} backdrop="static"
        keyboard={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title>
            Are you sure want to make <b>{field?.name}</b> as popular category ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary"  onClick={() => onCloseModal()}>No</Button>
          <Button variant="primary"  onClick={handleOnChange}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
   
  );
};



