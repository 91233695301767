import React from 'react';
import './Admin.css';
import Footer from "components/Footer"
import { NavBar } from './nav-bar/Navbar';
import { SideBar } from './side-bar/Sidebar';
import { AdminRouter } from 'routes/AdminRouters';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Admin = () => {
    return (
        <React.Fragment>
            <div className="app-wrap">
                <NavBar />
                <div className="app-container">
                    <SideBar />
                    <div className="app-main">
                        <AdminRouter />
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};