import { Switch, Route } from 'react-router-dom';
import { Dashboard } from 'pages/admin/dashboard/Dashboard';
import { Category } from 'pages/admin/category/Category';
import { ADMIN_ABOUT_US, 
        ADMIN_CATEGORY, 
        ADMIN_CONTACT_US, 
        ADMIN_DASHBOARD, 
        ADMIN_PROFILE, 
        ADMIN_SERVICE_DETAILS, 
        ADMIN_SERVICE_PROVIDERS, 
        ADMIN_CONSUMERS,
        ADMIN_PINCODE,
        ADMIN_SERVICE_PROVIDER_DETAILS } from 'constants/RoutePaths';
import { Profile } from 'pages/admin/profile/Profile';
import { ServiceProviders } from 'pages/admin/service-providers/ServiceProviders';
import { AboutUs } from 'pages/admin/about-us/AboutUs';
import { ContactUs } from 'pages/admin/contact-us/ContactUs';
import { ServiceDetails } from 'pages/admin/service-details/ServiceDetails';
import { ServiceProviderDeatils } from 'pages/admin/service-provider-details/ServiceProviderDeatils';
import { Consumers } from 'pages/admin/consumers/Consmers';
import { Pincodes } from 'pages/admin/pincode/Pincodes';
import { useState } from 'react';
import { getApiData } from 'helpers/CommonUtils';
import { useEffect } from 'react';

export const AdminRouter = () => {
    const [consumerData, setConsumerData]= useState([])
    const [serviceData, setServiceData]= useState([])
    const [playStoreData, setPlayStoreData]= useState([])
    const [categoryData, setCategoryData]= useState([])

    useEffect(() =>{
		getApiData(`/app/user/getConsumers`)
		.then((response) => {
			setConsumerData(response.data.data);
			
		})
		.catch((error) => {
			console.log(error)
		});
		;
	},[]);

    useEffect(() => {
        getApiData("/app/user/getServiceProvider")
          .then((response) => {
            setServiceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);


    useEffect(() => {
        getApiData("/app/playstore/appDownload")
          .then((response) => {
            setPlayStoreData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);


      useEffect(() => {
        getApiData("/app/category/list-category")
          .then((response) => {
            setCategoryData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);




    return (
        <Switch>
            {/* <Route path={ADMIN_DASHBOARD} component = { Dashboard } /> */}
            <Route path={ADMIN_DASHBOARD} >
              <Dashboard consumerData={consumerData} serviceData={serviceData} playStoreData={playStoreData} categoryData={categoryData} />
            </Route>

            <Route path={ADMIN_PROFILE} component = { Profile } />
            <Route path={ADMIN_SERVICE_PROVIDERS} component = { ServiceProviders } />
            <Route path={ADMIN_CATEGORY} component = { Category } />
            <Route path={ADMIN_ABOUT_US} component = { AboutUs } />
            <Route path={ADMIN_CONTACT_US} component = { ContactUs } />
            <Route path={ADMIN_CONSUMERS} component = { Consumers }  />
            <Route path={ADMIN_PINCODE} component = { Pincodes } />
            <Route path={`${ADMIN_SERVICE_PROVIDER_DETAILS}/:id`} component = { ServiceProviderDeatils } />
            <Route path={`${ADMIN_SERVICE_DETAILS}/:id`} component = { ServiceDetails } />
        </Switch>
    );
};