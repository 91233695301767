
import './Sidebar.css';
import logo from '../../../assets/images/admin-logo.png';
import { Link, useLocation } from 'react-router-dom';
import { 
    ADMIN_CATEGORY, 
    ADMIN_DASHBOARD, 
    ADMIN_SERVICE_PROVIDERS,
    ADMIN_CONSUMERS,
    ADMIN_PINCODE,
    // ADMIN_CONTACT_US
} from 'constants/RoutePaths';
import { useState } from 'react';
import { connect } from "react-redux";


const SideBarComponent = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const currentPath = pathname.split("/")[2];
    const [ cmsDropdown, setCmsDropdown ] = useState(false);

    return(
        <aside className={props.sideMenu ? 'app-side is-mini':'app-side'}>
            <div className="side-content ">
                <div className="user-profile">
                    <a href="javascript:void(0)" className="logo">
                        <img src = {logo} alt="Spare Hands Logo" />
                    </a>
                </div>
                <nav className="side-nav">
                    <ul className="unifyMenu">
                        <li className={currentPath === "dashboard" ? "active selected" : ""}>
                            <Link to={ADMIN_DASHBOARD}>
                                <span className="has-icon">
                                    <i className="icon-laptop_windows"></i>
                                </span>
                                <span className="nav-title">Dashboards</span>
                            </Link> 
                        </li>
                        <li className={(
                                currentPath === "service-providers" ||
                                currentPath === "service-provider-details" ||
                                currentPath === "service-details"
                            ) ? "active selected" : ""}>
                            <Link to={ADMIN_SERVICE_PROVIDERS}>
                                <span className="has-icon">
                                    <i className="icon-cogs"></i>
                                </span>
                                <span className="nav-title">Service Providers</span>
                            </Link>
                        </li>
                        <li className= {(
                            currentPath === "consumers"
                            ) ? "active selected" : ""}>
                            <Link to={ADMIN_CONSUMERS}>
                                <span className="has-icon">
                                    <i className="icon-cogs"></i>
                                </span>
                                <span className="nav-title">Consumers</span>
                            </Link>
                        </li>
                        <li className={currentPath === "categories" ? "active selected" : ""} onClick={ () => cmsDropdown ? setCmsDropdown(false) : null }>
                            <Link to={ADMIN_CATEGORY}>
                                <span className="has-icon">
                                    <i className="icon-list2"></i>
                                </span>
                                <span className="nav-title">Category</span>
                            </Link>
                        </li>
                        <li className={currentPath === "pincode" ? "active selected" : ""} onClick={ () => cmsDropdown ? setCmsDropdown(false) : null }>
                            <Link to={ADMIN_PINCODE}>
                                <span className="has-icon">
                                    <i className="icon-list2"></i>
                                </span>
                                <span className="nav-title">Pincode</span>
                            </Link>
                        </li>
                        
                        {/* <li className={ cmsDropdown ? (currentPath == "about-us" || currentPath == "contact-us") ? "active selected" : "active" : ""}>
                            <a href="javascript:void(0);" className="has-arrow" aria-expanded={ cmsDropdown ? 'true':'false' }
                                onClick={() => setCmsDropdown(!cmsDropdown)}>
                                <span className="has-icon">
                                    <i className="icon-display"></i>
                                </span>
                                <span className="nav-title">CMS</span>
                            </a>
                            <ul aria-expanded={ cmsDropdown ? 'true':'false' }
								className= { cmsDropdown ? 'collapse in':'collapse' }>
                                <li>
                                    <Link to={ADMIN_ABOUT_US} 
                                        className={ currentPath == "about-us" ? 'current-page' : ''}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={ADMIN_CONTACT_US}
                                        className={ currentPath == "contact-us" ? 'current-page' : ''}>Contact Us</Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

const mapStateToProps = (state: any) => ({
    sideMenu: state.sideMenu
});
// const mapDispatchToProps = (dispatch:any) => ({
//     toggleSideMenu: () => dispatch({type: TOGGLE_SIDE_MENU})
// })

export const SideBar = connect(mapStateToProps, null)(SideBarComponent);
