import React, { useEffect, useState } from "react";
import './ConsumerList.css';
import { Link } from "react-router-dom";
import { getApiData,getPutData } from "helpers/CommonUtils";
import { ADMIN_DASHBOARD } from "constants/RoutePaths";
import defaultUserImage from '../../../assets/images/default_user.jpg';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Pagination from "rc-pagination";
import '../../../assets/css/pagination.css'


export const Consumers = () => {
	const [consumerList, setConsumerList] = useState<any[]>([]);
	const [refresh, setRefresh] = useState(false);
	const [viewMode, setViewMode] = useState('list');
	const [open, setOpen] = useState(false);
	const [field, setField]=useState({
		id:"",
		status: 0,
		note:""
	  })
	console.log(consumerList.length)
	const [perPage, setPerPage] = useState(10);
	const [size, setSize] = useState(perPage);
	const [current, setCurrent] = useState(1);
	
	useEffect(() =>{
		getApiData(`/app/user/getConsumers`)
		.then((response) => {
			setConsumerList(response.data.data);
		})
		.catch((error) => {
			console.log(error)
		});
		;
	},[refresh]);


	const PerPageChange = (value) => {
		setSize(value);
		const newPerPage = Math.ceil(consumerList.length / value);
		if (current > newPerPage) {
			setCurrent(newPerPage);
		}
	}
	
	const getData = (current, pageSize) => {
		// Normally you should get the data from the server
		return consumerList.slice((current - 1) * pageSize, current * pageSize);
	};
	
	const PaginationChange = (page, pageSize) => {
		setCurrent(page);
		setSize(pageSize)
	}
	
	const PrevNextArrow = (current, type, originalElement) => {
		if (type === 'prev') {
			return <button><i className="fa fa-angle-double-left"></i></button>;
		}
		if (type === 'next') {
			return <button><i className="fa fa-angle-double-right"></i></button>;
		}
		return originalElement;
	}



	const openModal = (item: any) => { 
		setField({...field, id:item.id, status:item.status === 1 ? 2 : 1,note: item.note})
		setOpen(!open);
	};
 
 const onCloseModal = () => {
	 setOpen(false);
 };

 const handleOnChange = async () => {
    getPutData('app/user/setStatus', field)
    .then((response) => {
      if(response.data.status === 1){
      toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        setOpen(false);
      }else{
      toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setOpen(false);
      } 
  });
    setField({...field, id:'', status: 0,note: ''});
    setRefresh(!refresh);
  };
console.log(consumerList);
    return (
		<React.Fragment>
			<header className="main-heading">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
							<div className="page-icon">
							<i className="icon-cogs"></i>
							</div>
							<div className="page-title">
								<h5>Consumer List</h5>
								<h6 className="sub-heading"></h6>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={ADMIN_DASHBOARD}>Dashboard</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Consumer List</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</header>

			<div className="main-content">
				<div className="card">
					<div className="card-header">
						<aside className="row h-100 justify-content-center align-items-center">
							<div className="col-md-8">
								Consumer View
							</div>
							<div className="col-md-4">
								<ul className="grid-ul">
									<li>
										<a href="javascript:void(0)" className={viewMode == 'list' ? 'list-view active' : 'list-view'} onClick={() => setViewMode('list')}>
											<span className="icon-th-list"></span>
										</a>
									</li>
									<li>
										<a href="javascript:void(0)" className={viewMode == 'grid' ? 'grid-view active' : 'grid-view'} onClick={() => setViewMode('grid')}>
											<span className="icon-th-large"></span>
										</a>
									</li>
								</ul>
							</div>
						</aside>
					</div>
				</div>
				<div className="row">
				{getData(current,size).map((consumer, index) => (
				
				<div className={viewMode == 'list' ? 'col-md-12' : 'col-md-12 grid'} key={index}>
					<div className="card">
					<div className="card-body">
						<div className="row h-100 justify-content-center align-items-center">
							<aside className="col-md-3 full-width">
								{ consumer.image != null ? 
									<img src={`${process.env.REACT_APP_BASE_URL}${consumer.image}`} className="img-fluid blog-thumb" alt="User Image" /> : 
									<img src={defaultUserImage} className="img-fluid blog-thumb" alt="User Image" />
								}
							</aside>
							<aside className="col-md-6 full-width">
								<div className="detail-box">
								<ul className="profile-ul">
									<li><span className="icon-user"></span> {consumer.first_name} {consumer.last_name}</li>
									<li><span className="icon-drafts"></span> {consumer.email}</li>
									<li><span className="icon-stay_primary_portrait"></span> +91 - {consumer.phone}</li>
								</ul>
								</div>
							</aside>
							<aside className="col-md-3 full-width">
								<div className="right-detailbox">
								{/* <div className="location-ico">
									<i className="icon-location"></i>
								</div> */}
								<div className="row open-today h-100 justify-content-center align-items-center">
										<div className="col-md-6">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id={`custom-checkbox-${index}`}
													onChange={() => openModal(consumer)}
													checked={consumer.status === 2 ? true : false}
													className="check"
												/>
												<b className="b switch"></b>
												<b
												className={consumer.status == 2 ? "b track" : "after"}
												></b>
											</div>
										</div>
										
										{/* <div className="col-md-6 edit-service">
											<Link className="btn" to={`${consumer.id}`}>
												<i className="icon-eye-outline"></i> View Details											</Link>
										</div> */}
									</div>
								</div>
							</aside>
						</div>
					</div>
					</div>
				</div>
				))}
			</div>
			<div className="table-filter-info">
        <Pagination
          className="pagination-data"
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={consumerList.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          itemRender={PrevNextArrow}
          onShowSizeChange={PerPageChange}
        />
      </div>

		</div>
			{/* Open Modal for Block/Un-Block */}
			<Modal show={open} onHide={onCloseModal} backdrop="static"
				keyboard={false} aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
				<Modal.Title>Block / Unblock Service Provider</Modal.Title>
				</Modal.Header>

				<Modal.Body>
				<p>Please Write a note</p>
				<textarea rows={5} cols={55} 
					value={field.note} 
					onChange={(e)=>setField({...field,note:e.target.value})}>
				</textarea>
				</Modal.Body>

				<Modal.Footer>
				<Button variant="secondary" onClick={() => onCloseModal()}>Cancel</Button>
				<Button variant="primary" onClick={handleOnChange}>Update</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
    );
};