import React, { useEffect, useState } from "react";
import "./ServiceDetails.css";
import SimpleImageSlider from "react-simple-image-slider";
import { getApiData, getPutData } from "helpers/CommonUtils";
import { Link } from "react-router-dom";
import { ADMIN_DASHBOARD, ADMIN_SERVICE_PROVIDERS } from "constants/RoutePaths";
import { Accordion, Card, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

export const ServiceDetails = (props: any) => {
  const [serviceDetails, setServiceDetails] = useState<any>();
  const [images, setImages] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [field, setField]=useState({
		id:"",
		activity: 0,
		note:""
	});
  useEffect(() => {
    getApiData(`/app/portfolio/portfolioDetails/${props.match.params.id}`)
		.then((response) => {
      const res = response.data;
      setServiceDetails(res.data[0]);
      let finalImages: Array<{url: string}> = [];
      res && res.data[0]?.images?.map((i) =>
      finalImages.push({
        url: `${process.env.REACT_APP_BASE_URL}${i?.image}`,
      })
      );
      setImages(finalImages);
		})
		.catch((error) => {
      console.log(error);
		});
  }, [refresh]);
  
  console.log("==========",images);
  // console.log("images.map",images);

  const openModal = (item: any) => { 
    setField({...field, id:item.id, activity:item.activity === 1 ? 2 : 1,note: item.note})
    setOpen(!open);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleOnChange = async () => {
		getPutData('app/common/protfolioActivity', field)
		.then((response) => {
		  if(response.data.status === 1){
		  toast.success(response.data.message, {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  });
			setOpen(false);
		  }else{
		  toast.error(response.data.message, {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  });
			  setOpen(false);
		  } 
	  	});
		
	  setField({...field, id:'', activity: 0, note: ''});
		setRefresh(!refresh);
	};

  return (
    <React.Fragment>
      <header className="main-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div className="page-icon">
                <i className="icon-cogs"></i>
              </div>
              <div className="page-title">
                <h5>Service Details</h5>
                <h6 className="sub-heading">Welcome to Spare Hands service</h6>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={ADMIN_DASHBOARD}><span className="icon-home4"></span> Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to={ADMIN_SERVICE_PROVIDERS}>Service Providers</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Service Details</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </header>
      
      <div className="main-content">
        <div className="row">
          <aside className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
            <div className="card">
              <div className="card-header">
                {serviceDetails?.title}
              </div>
              <div className="card-body">
                <p>{serviceDetails?.description}</p>
              </div>
            </div>
            <Accordion defaultActiveKey="0">
                { 
                  serviceDetails?.locations.length > 0 && serviceDetails?.locations.map((location, i) => (
                    <Card key={i}>
                      <Accordion.Toggle as={Card.Header} eventKey={`${i}`}>
                        <span>Open Hour</span> |
                        {location.location.length > 0 && location.location.map((areas) => (
                            <i className="icon-location">{areas.value} </i> 
                        ))}
                       
                         <div className="hour-right"><span className="icon-today"></span><b>Special Hours:</b>  {location.special_hours.replace(/["']/g, "")}</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${i}`}>
                        <Card.Body>
                          <table className="table">
                            <tbody>
                              {location.availabilities.length > 0 && location.availabilities.map((availabity, j) => (
                                <tr key={j}>
                                  <td>{availabity.day}</td>
                                  <td className={(availabity.is_closed == 0) ? 'open-bullet' : 'close-bullet'}>{(availabity.is_closed == 0) ? 'Open' : 'Closed'}</td>
                                  {(availabity.is_closed == 0) ? 
                                  <td>
                                    {availabity.hours.length > 0 && availabity.hours.map((hour) => (
                                      <span>{hour.open_time}</span>  
                                      ))}
                                  </td> : <td> <span></span> </td>}

                                  <td className="to-hr"> {(availabity.is_closed == 0) ? '- To -' : '' }</td>
                                  {(availabity.is_closed == 0) ? 
                                  <td>
                                    {availabity.hours.length > 0 && availabity.hours.map((hour) => (
                                      <span>{hour.close_time}</span>  
                                      ))}
                                  </td> : <td> <span></span> </td>}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                }
            </Accordion>
          </aside>
          <aside className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <div className="card">
              <div className="card-body">
                {images.length == 0 ?
                      <img src={`${images.url}app/uploads/portfolio/service_image.jpg`}
                      className="serviceImage" 
                      style={ {height: '300px', width: '250px'} }
                      alt="Service Image" />
                     : 
                     <SimpleImageSlider
                     width={400}
                     height={250}
                     images={images}
                     showNavs
                     showBullets
                   />
                 } 
              </div>
            </div>
            
            <div className="card">
              <div className="card-body">
                <div className="right-top-section">
                <table className="table-responsive">
                  <tbody>
                    <tr>
                      <td>Price:</td>
                      <td colSpan={3}><span className="rupees-symbol">&#8377;</span> {serviceDetails?.price}</td>
                    </tr>
                    <tr>
                      <td>Category:</td>
                      <td>{serviceDetails?.categry_name}</td>
                      <td></td>
                      <td></td>
                      {/* <td>Review:</td>
                      <td className="review-nochecked">4.8 
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star"></span>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                </div>
                
              </div>
              <div className="card-footer">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-sd-1`}
                    onChange={() => openModal(serviceDetails)}
                    checked={serviceDetails?.activity === 2 ? true : false}
                    className="check"
                  />
                  <b className="b switch"></b>
                  <b
                  className={serviceDetails?.activity == 2 ? "b track" : "after"}
                  ></b>
                </div>
              </div>
            </div>
          </aside>
				</div>
      </div>
      {/* Open Modal for Block/Un-Block */}
			<Modal show={open} onHide={onCloseModal} backdrop="static"
				keyboard={false} aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
				  <Modal.Title>Block / Unblock Service Provider</Modal.Title>
				</Modal.Header>

				<Modal.Body>
          <p>Please Write a note</p>
          <textarea rows={5} cols={55} 
            value={field.note} 
            onChange={(e)=>setField({...field,note:e.target.value})}>
          </textarea>
				</Modal.Body>

				<Modal.Footer>
          <Button variant="secondary" onClick={() => onCloseModal()}>Cancel</Button>
          <Button variant="primary" onClick={handleOnChange}>Update</Button>
				</Modal.Footer>
			</Modal>
    </React.Fragment>
  );
};