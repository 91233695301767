import { ADMIN_DASHBOARD } from "constants/RoutePaths";
import {
  getApiData,
  getPostData,
  getPutData,
  deleteData
} from "helpers/CommonUtils";
import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import '../../../assets/css/pagination.css'
import Pagination from "rc-pagination";
import { Button, Modal } from "react-bootstrap";

export const Pincodes = () => {
  // Get Pincodes Data
  const [pincodes, setPincodes] = useState<any[]>([]);
  const [refresh,setRefresh] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [open, setOpen] = useState(false);
  const [fieldPin, setFieldPin] = useState();


  const uniquePincode = pincodes.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.pincode === current.pincode)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(uniquePincode.length / value);
    if (current > newPerPage) {
        setCurrent(newPerPage);
    }
}

const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return uniquePincode.slice((current - 1) * pageSize, current * pageSize);
};

const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
}

const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
        return <button><i className="fa fa-angle-double-left"></i></button>;
    }
    if (type === 'next') {
        return <button><i className="fa fa-angle-double-right"></i></button>;
    }
    return originalElement;
}
  
  useEffect(() => {
    getApiData('/app/pincodes/pincodesAreas')
      .then((response) => {
        setPincodes(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }, [refresh]);

 

//Delete PINCODE Modal 

const openModal = (index: any) => {
  setFieldPin(index);
  setOpen(!open);
};


const onCloseModal = () => {
  setOpen(false);
};


const onDeletePincode = (index: any) => {
  console.log("Debug>>>>>>txs",index.area_id);
  setOpen(false);
  deleteData(`/app/pincodes/deletePincode/${index.area_id}`).then(
    (response) => {
      if (response.data.success === true) {
        setRefresh(!refresh);
        toast.success("Pincode Deleted Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Pincode Delete Failed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  );
};

  
  // Add Pincode
  const [field,setField]=useState({pincode:'',areas:[{area:''}]})
  const { handleSubmit, formState: { errors } } = useForm();
  const onSubmit = () => {
    getPostData('/app/pincodes/createPincode', field)
      .then((response) => {
        //if (response.data.success === true) {
          setRefresh(!refresh);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        //} 
      })
      .catch((err)=>{
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
       

      })
      
  }

  //handle pincode input change
  const set = name => {
    return ({ target: { value } }) => {
      setField(oldValues => ({...oldValues, [name]: value }));
    }
  };

  // handle input change
  const handleInputChange = (e:any , i:any ) => {
    field.areas[i][e.target.name] = e.target.value;
    setField({...field});
  };
  
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    field.areas.splice(index, 1);
    setField({...field});
  };
  
  // handle click event of the Add button
  const handleAddClick = () => {
    field.areas.push({area:''});
    setField({...field});
  };

    return (
        <React.Fragment>
            <header className="main-heading">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <div className="page-icon">
                                <i className="icon-laptop_windows"></i>
                            </div>
                            <div className="page-title">
                                <h5>Pincodes</h5>
                                <h6 className="sub-heading">Welcome to Spare Hands</h6>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to={ADMIN_DASHBOARD}>Dashboard</Link></li>
                              <li className="breadcrumb-item active" aria-current="page">Pincode</li>
                            </ol>
                          </nav>
                      </div>
                    </div>
                </div>
            </header>
    <div className="main-content">
        <div className="row gutters">
          <aside className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <div className="card">
              <div className="card-header">Add New Pincode</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter Pincode"
                      name= 'pincode'
                      value={field.pincode}
                      onChange={set('pincode')}
                      required
                      // {...register("name", { required: true })}
                    />
                  </div>
                  {errors.categoryName && errors.categoryName.type === "required" && (
                    <span className="errorMsg" style={{ color: 'red' }}>
                      * It is required.
                    </span>)}
                  <div className="form-group"><br></br>
                  {field.areas.map((x, i) => {
                    return (
                      <div className="box">
                        &nbsp;
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="area"
                        placeholder="Enter area"
                        value={x.area}
                        onChange={e => handleInputChange(e, i)}
                      /><br></br>
                        <div className="btn-box">
                          {field.areas.length !== 1 && <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRemoveClick(i)}>Remove</button>}&nbsp;
                          {field.areas.length - 1 === i && <button className="btn btn-sm btn-primary" onClick={handleAddClick}>Add</button>}
                        </div>
                      </div>
                    );
                  })}
                  </div><br></br>
                  <button className="btn btn-sm btn-primary" type="submit">
                    <i className="icon icon-add-to-list"></i>&nbsp;
                    Create</button>
                </form>
              </div>
            </div>
          </aside>
          <aside className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
            <div className="card">
              {/* {loading? <LoaderHtml /> : null} */}
              {getData(current,size).map((pincode,index) => (
                <div
                  id="accordionIcons"
                  className="accordion-icons"
                  role="tablist">
                  <div className="mb-0">
                    <div className="card-header" role="tab" id="headingFour">
                      <h5 className="mb-0">
                        <a
                          data-toggle="collapse"
                          href="javascript:void(0)"
                          className="collapsed"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        // onClick={() => toggleExpand ? setToggleExpand(true) : setToggleExpand(false)}
                        >
                          <span>{pincode.pincode}</span>
                        </a>
                        <ul className="category-action">
                          {/* <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => { }}
                              className="ico-edit">
                              <span className="icon-pencil3"></span>
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => {} }
                              className="ico-delete">
                              <span className="icon-trash"></span>
                            </a>
                          </li>
                        </ul>
                      </h5>
                    </div>
                    {pincodes.map((area) => (
                    area.id === pincode.pincode_id ?  
                    <div id="collapseFour"
                      // className={
                      //   toggleExpand === true ? "collapse show" : "collapse"
                      // }
                      role="tabpanel"
                      aria-labelledby="headingFour"
                      data-parent="#accordionIcons">
                      <div className="card-body">
                      
                          <ul className="sub-categoryul">
                            <li>
                              <span>{area.address}</span>
                              <ul className="category-action">
                                {/* <li>
                                  <a href="javascript:void(0)"
                                    onClick={() => { }}
                                    className="ico-edit">
                                    <span className="icon-pencil3"></span>
                                  </a>
                                </li> */}
                                <li>
                                  <a href="javascript:void(0)"
                                    className="ico-delete"
                                    onClick={() => {openModal(area)}}
                                  >
                                    <span className="icon-trash"></span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                         
                         
                      
                       
                      </div>
                    </div>
                    : null
                       
                       ))}
                  </div>
                </div>
              ))}
            </div>
          </aside>
          </div>
          
          <div className="table-filter-info">
        <Pagination
          className="pagination-data"
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={uniquePincode.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          itemRender={PrevNextArrow}
          onShowSizeChange={PerPageChange}
        />
      </div>






          
       
    </div>

    
      <div style={{ marginTop: 20 }}>{JSON.stringify(field.areas)}</div>
      
      <Modal show={open} onHide={onCloseModal} backdrop="static"
        keyboard={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title>
            Are you sure want to Delete PINCODE ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary"  onClick={() => onCloseModal()}>No</Button>
          <Button variant="primary"  onClick={() => onDeletePincode(fieldPin)}>Yes</Button>
        </Modal.Footer>
      </Modal>
        </React.Fragment>
    );
  };






