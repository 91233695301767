import React, { useState,useEffect } from 'react';
import { getPostData, getPutData, getUser,getApiData } from 'helpers/CommonUtils';
import './Profile.css';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';


interface IFormInput {
    id: number;
    old_password: string;
    password: string;
    confirm_password: string;
}

export const Profile = () => {
    const userData = getUser();
    const [refresh, setRefresh] = useState(false);

    //Profile Edit
    const [profileImage ] = useState<any>();
    // const [ userData1, setUserData ] = useState<any>();
    // console.log(userData1);
    const [toggleEdit, setToggleEdit] = useState(true);
    const[userDataStorage, setUserDataStorage ] = useState({
        id: userData.id,
        phone: userData.phone, 
        user_name: userData.user_name, 
        email: userData.email, 
        profile_pic: userData.profile_pic
    });

    const handleChange = (event: any) => {
        let reader = new FileReader();
        console.log("reader12",reader);
        const { name, value } = event.target;
        setUserDataStorage({...userDataStorage,  
            id: userData.id,
            [name]: value,
        })
    }

    const changeProfileImage = (event: any) => {
        // let file = event.target.files[0];
        // let reader = new FileReader();
        // reader.readAsDataURL(file);
        // console.log("reader",file);
        
        // reader.onload = (e) => {
        //     console.log("e",e);
        //     console.warn("event.target.result",reader.result)
        //     // setUserDataStorage({...userDataStorage,
        //     //     profile_pic: reader.result
        //     // });
        // }

        if (event.target.files) {    
            setUserDataStorage({...userDataStorage, profile_pic: event.target.files[0] })
            const formData = new FormData();
            formData.append("profile_pic",event.target.files[0]);
            formData.append("id",userDataStorage.id);
            getPutData('/login/update-profileImage', formData)
            .then((response) => {
                console.log("response ", response);
                setRefresh(!refresh)
                toast.success('Profile Image Updated Successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }).catch(error => {
                console.error(error.response);
                toast.error('Profile Image Update Failed', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            });
        } else {
            setUserDataStorage({...userDataStorage})
        }
    }

    useEffect(() =>{
        getApiData(`/login/get-profile/${userDataStorage.id}`)
            .then((response: any) => {
                console.log("response.data",response.data);
                //setUserData(response.data.adminInfo);
                setUserDataStorage({...userDataStorage, profile_pic: response.data.adminInfo.profile_pic });
                sessionStorage.setItem("userInfo", JSON.stringify(response.data.adminInfo))
            })
            .catch((error) => {
                console.log(error);
            });
	}, [refresh]);

    const handleSave = () => {
        const formData = new FormData();
        formData.append("profile_pic",userDataStorage.profile_pic);
        formData.append("id",userDataStorage.id);
        formData.append("phone",userDataStorage.phone);
        formData.append("user_name",userDataStorage.user_name);
        formData.append("email",userDataStorage.email);
        setToggleEdit(true);
        getPutData('/login/update-profile', formData)
        .then((response) => {
            console.log("response ", response.data);
            toast.success('Profile Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                setRefresh(!refresh)
          }).catch(error => {
            console.error(error.response);
            toast.error('Profile Update Failed', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
          });
    }
    const handleCancel = () => {
        toast.warn("You didn't changed anything!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            });
        setToggleEdit(true);
        setUserDataStorage({
            id: userData.id,
            phone: userData.phone, 
            user_name: userData.user_name, 
            email:userData.email, 
            profile_pic: userData.profile_pic
        });
    }

    //Change Password
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (dataPwd: IFormInput) => {
        if(dataPwd.password!== dataPwd.old_password && dataPwd.password == dataPwd.confirm_password){
            dataPwd.id = userDataStorage.id;
            getPostData('/login/change-password', dataPwd)
            .then((response) => {
                if (response.data.message === true) {
                    toast.success('Password Updated Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                } else{
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        });
                }
            }).catch(error => {
                toast.error(error.response, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            });
        }else{
            toast.success('Old Password and  New Password is same!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
       
    }
    return(
        <React.Fragment>
            <header className="main-heading">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <div className="page-icon">
                            <i className="icon-v-card"></i>
                            </div>
                            <div className="page-title">
                                <h5>Profile Details</h5>
                                <h6 className="sub-heading"></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-content">
                <div className = "row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        <div className ="card">
                            <div className="card-header">
                                Profile Details
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-sm-4 imageAlign">
                                            <div className="profilePic">
                                            {!profileImage?<img 
                                                    src= {`${process.env.REACT_APP_BASE_URL}${userDataStorage.profile_pic}`}
                                                    className="rounded float-left profileImage"
                                                    alt="Profile Image"  />: null}
                                                {!toggleEdit?
                                                <div className="editProfilePic">
                                                    {profileImage && <img 
                                                    src={userDataStorage.profile_pic}
                                                    className="rounded float-left profileImage"
                                                    alt="Profile Image"  />}
                                                    <label htmlFor="inputImageUpload" 
                                                        className="icon icon-edit2 editImage" />
                                                    <input type="file"
                                                        name="profile_pic"
                                                        id="inputImageUpload" 
                                                        accept="image/*" 
                                                        onChange= {(event) => changeProfileImage(event)} />
                                                </div>: null}
                                                
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="form-group row">
                                                <div className="col-sm-3">
                                                    <label className="col-form-label profileLabel">
                                                        Username:
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input type="text" className="form-control" 
                                                        id={'user_name' + userDataStorage.id} 
                                                        value={userDataStorage.user_name}
                                                        name="user_name"
                                                        onChange= {e => handleChange(e)}
                                                        disabled={toggleEdit}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <label className="col-form-label profileLabel">
                                                            Mobile:
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" 
                                                            id="phone"
                                                            value={userDataStorage.phone}
                                                            name="phone"
                                                            onChange= {e => handleChange(e)}
                                                            disabled={toggleEdit}
                                                            />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <label className="col-form-label profileLabel">
                                                            Email:
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" 
                                                            id="email"
                                                            value={userDataStorage.email}
                                                            name="email"
                                                            onChange= {e => handleChange(e)}
                                                            disabled={toggleEdit}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                        <div className="editButton">
                                            {toggleEdit?<button
                                                onClick={() => setToggleEdit(!toggleEdit)}
                                                className="btn btn-primary btn-sm">
                                                <i className="icon icon-edit2"></i> Edit
                                            </button>: null}
                                        </div>
                                        {!toggleEdit?
                                            <div className="Save Button">
                                                <button
                                                    onClick={handleCancel}
                                                    className="btn btn-danger btn-sm">
                                                    <i className="icon icon-cancel2"></i>
                                                    <span>Cancel</span>
                                                </button>
                                                &nbsp;&nbsp;
                                                <button
                                                    onClick={handleSave}
                                                    className="btn btn-primary btn-sm">
                                                    <i className="icon icon-save2"></i> 
                                                    <span>Save</span>
                                                </button>
                                            </div>: null}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className = "col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <div className= "card">
                                <div className= "card-header">
                                    Change Password &nbsp;
                                    <a href="#!"
                                        className="info-tooltip"
                                        data-mdb-toggle="tooltip" 
                                        title="*Note - Password's length should be within 6-8 characters & New Password must be different from current password">
                                        <i className="icon-info-with-circle" style={{color: 'red'}}></i>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <form onSubmit = { handleSubmit(onSubmit)}>
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <input type="text"
                                                    className="form-control"
                                                    placeholder="Current Password"
                                                    {...register("old_password", { required: true })}
                                                    />
                                                </div>
                                            </div>
                                            
                                            {errors.old_password && errors.old_password.type === "required" && (
                                                <div className="card">
                                                    <small className="errorMsg" style={{ color: 'red' }}> 
                                                        * Password is required.
                                                    </small>
                                                </div>)}

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="New Password"
                                                        {...register("password", { required: true, maxLength: 8, minLength: 6})}
                                                    />
                                                </div>
                                            </div>
                                            
                                            {errors.password && errors.password.type === "required" && (
                                                <div className="card">
                                                    <small className="errorMsg" style={{ color: 'red' }}> 
                                                    * Password is required.
                                                    </small> 
                                                </div>)}
                                            {errors.password && errors.password.type === "maxLength" && (
                                                <div className="card">
                                                    <small className="errorMsg" style={{ color: 'red' }}> 
                                                        * Password should be 8 characters maximum.
                                                    </small>
                                                </div>)}
                                            {errors.password && errors.password.type === "minLength" && (
                                                <div className="card">
                                                    <small className="errorMsg" style={{ color: 'red' }}> 
                                                        * Password should be 6 characters minimum.
                                                    </small>
                                                </div>)}

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <input type="password"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    {...register("confirm_password", { required: true } )} 
                                                    />
                                                </div>
                                            </div>
                                            
                                                {errors.confirm_password && 
                                                    errors.confirm_password.type === "required" && (
                                                <div className="card">
                                                    <small className="errorMsg" style={{ color: 'red' }}> 
                                                        * Password is required.
                                                    </small>
                                                    </div>)}
                                            
                                        </div>
                                        <div className="text-right">
                                            <button type="submit"
                                                className="btn btn-primary btn-sm">
                                                <i className="icon icon-save2"></i>
                                                    Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};