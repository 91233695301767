import { getApiData,getPutData } from "helpers/CommonUtils";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { GET_SERVICE_PROVIDERS } from "redux/actions";
import "./ServiceProviders.css";
import { toast } from 'react-toastify';
import { ADMIN_DASHBOARD, ADMIN_SERVICE_PROVIDER_DETAILS } from "constants/RoutePaths";
import { Modal, Button } from 'react-bootstrap';
import defaultUserImage from '../../../assets/images/default_user.jpg';
import Pagination from "rc-pagination";
import '../../../assets/css/pagination.css'

const ServiceProvidersComponent = (props: any) => {

  const [field, setField]=useState({
    id:"",
    status: 0,
    note:""
  })

  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const [open, setOpen] = useState(false);
  
  const openModal = (item: any) => { 
    setField({...field, id:item.id, status:item.status === 1 ? 2 : 1,note: item.note})
    setOpen(!open);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const [userList, setUserList] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);


  const handleOnChange = async () => {
    getPutData('app/user/setStatus', field)
    .then((response) => {
      if(response.data.status === 1){
      toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        setOpen(false);
      }else{
      toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setOpen(false);
      } 
  });
    setField({...field, id:'', status: 0,note: ''});
    setRefresh(!refresh);
  };

  // setCheckedState(updatedCheckedState);

  useEffect(() => {
    getApiData("/app/user/getServiceProvider")
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);


  console.log(userList);
  props.getServiceProviders();
  // console.log("serviceProviders ", props.serviceProviders);

  const PerPageChange = (value) => {
		setSize(value);
		const newPerPage = Math.ceil(userList.length / value);
		if (current > newPerPage) {
			setCurrent(newPerPage);
		}
	}
	
	const getData = (current, pageSize) => {
		// Normally you should get the data from the server
		return userList.slice((current - 1) * pageSize, current * pageSize);
	};
	
	const PaginationChange = (page, pageSize) => {
		setCurrent(page);
		setSize(pageSize)
	}
	
	const PrevNextArrow = (current, type, originalElement) => {
		if (type === 'prev') {
			return <button><i className="fa fa-angle-double-left"></i></button>;
		}
		if (type === 'next') {
			return <button><i className="fa fa-angle-double-right"></i></button>;
		}
		return originalElement;
	}

  return (
    <React.Fragment>
      <header className="main-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div className="page-icon">
                <i className="icon-cogs"></i>
              </div>
              <div className="page-title">
                <h5>Service Providers</h5>
                <h6 className="sub-heading">Welcome to Spare Hands</h6>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={ADMIN_DASHBOARD}>Dashboard</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Service Providers</li>
								</ol>
							</nav>
						</div>
          </div>
        </div>
      </header>
     
      <div className="main-content">
      
        <div className="card">
          <div className="card-header">All Service Providers</div>
          <div className="card-body">
            <table className="table m-0">
              <thead className="thead-inverse">
                <tr>
                  <th>Sl No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th> 
                  <th>Mobile</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {getData(current,size).map((item, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>{ item.image != null ? 
									<img src={`${process.env.REACT_APP_BASE_URL}${item.image}`} className="img-fluid blog-thumb" alt="User Image" /> : 
									<img src={defaultUserImage} className="img-fluid blog-thumb" alt="User Image" />
								}</td>
                    <td>{`${item.first_name} ${item?.last_name}`}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>
                      <div className="toggle-switch">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          onChange={() => openModal(item)}
                          checked={item.status === 2 ? true : false}
                          className="check"
                        />
                        <b className="b switch"></b>
                        <b
                          className={item.status == 2 ? "b track" : "after"}
                        ></b>
                      </div>
                    </td>
                    <td>
                      <ul className="table-action">
                        <li>
                          <Link to={`${ADMIN_SERVICE_PROVIDER_DETAILS}/${item.id}`}>
                            <span className="icon-eye-outline"></span>
                          </Link>
                        </li>
                        {/* <li><a href="#" className="ico-edit"><span className="icon-pencil3"></span></a></li>
                            <li><a href="#" className="ico-delete"><span className="icon-trash"></span></a></li> 
                        */}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="table-filter-info">
        <Pagination
          className="pagination-data"
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={userList.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          itemRender={PrevNextArrow}
          onShowSizeChange={PerPageChange}
        />
      </div>
          
        </div>
      </div>

      <Modal show={open} onHide={onCloseModal} backdrop="static"
        keyboard={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title>Block / Unblock Service Provider</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Please Write a note</p>
          <textarea rows={5} cols={55} 
              value={field.note} 
              onChange={(e)=>setField({...field,note:e.target.value})}>
          </textarea>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => onCloseModal()}>Cancel</Button>
          <Button variant="primary" onClick={handleOnChange}>Update</Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  serviceProviders: state.serviceProviders,
});

const mapDispatchToProps = (dispatch: any) => ({
  getServiceProviders: () => dispatch({ type: GET_SERVICE_PROVIDERS }),
});

export const ServiceProviders = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProvidersComponent);
