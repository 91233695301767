export const ROOT = "/";
export const LOGIN = "/login";

export const ADMIN = "/";
export const ADMIN_DASHBOARD = "/dashboard";
export const ADMIN_PROFILE = "/profile";
export const ADMIN_SERVICE_PROVIDERS = "/service-providers";
export const ADMIN_CATEGORY = "/categories";
export const ADMIN_ABOUT_US = "/about-us";
export const ADMIN_CONTACT_US = "/contact-us";
export const ADMIN_SERVICE_PROVIDER_DETAILS = "/service-provider-details";
export const ADMIN_SERVICE_DETAILS = "/service-details";
export const ADMIN_CONSUMERS = "/consumers";
export const ADMIN_PINCODE = "/pincode";