import { getUser, removeUserSession } from 'helpers/CommonUtils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_PROFILE } from '../../../constants/RoutePaths';
import './Navbar.css';
import { connect } from "react-redux";
import { TOGGLE_SIDE_MENU } from '../../../redux/actions';

const NavBarComponent = (props: any) => {
    // const BASE_URL = "http://139.59.24.214/spare-hands-api";

    const [ sideNavToggle, setSideNavToggle ] = useState(false);
    const [ rightDropdownToggle, setRightDropdownToggle ] = useState(false);
    // const [ wrapperRef, setWrapperRef ] = useState();

    const userData = getUser();
    let menuClass = `dropdown-menu lg dropdown-menu-right${rightDropdownToggle ? " show" : " hide"}`;
    const toggleOpen = () => {
        setRightDropdownToggle(!rightDropdownToggle);
        menuClass = `dropdown-menu lg dropdown-menu-right${rightDropdownToggle ? " show" : " hide"}`;
    };

    console.log("debug>>>>>>",menuClass);

    const handleLogout = () =>{
		removeUserSession();
	}

    const handleSideNavClick = () => {
        sideNavToggle? setSideNavToggle(false): setSideNavToggle(true);
        props.toggleSideMenu();
    };

    // handleClickOutside(event) {
    //     if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
    //       alert('You clicked outside of me!');
    //       this.setState({ showOptions: false });
    //     }
    // }
    
    // componentDidMount() {
    //     document.addEventListener('mousedown', this.handleClickOutside);
    // }
    
    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    // }

    return(
        <header className="app-header">
            <div className="container-fluid">
                <div className="row gutters">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-8">
                        <a className="mini-nav-btn" 
                            onClick={handleSideNavClick}
							href="javascript:void(0);" id="app-side-mini-toggler">
                            <i className={ sideNavToggle === true? 'icon-arrow_forward':'icon-arrow_back' }></i>
                        </a>
                        <a href="javascript:void(0);" data-toggle="onoffcanvas" className="onoffcanvas-toggler" aria-expanded="true">
                            <i className="icon-chevron-thin-left"></i>
                        </a>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                        <ul className="header-actions">
                            <li className={rightDropdownToggle ? "show" : ""}>
                                <a href="javascript:void(0);" 
                                    id="userSettings" className="user-settings" 
                                    data-toggle="dropdown" aria-haspopup="true"
                                    onClick={toggleOpen}>
                                    {/* // ref={(node) => setWrapperRef(node)}> */}
                                    <img className="avatar" 
                                        src={` ${process.env.REACT_APP_BASE_URL}${userData.profile_pic}`} 
                                        alt="User Thumb" />
                                    <span className="user-name">{userData.user_name}</span>
                                    <i className="icon-chevron-small-down"></i>
                                </a>
                                <div className={menuClass} aria-labelledby="userSettings">
                                    <ul className="user-settings-list">
                                        <li>
                                            <Link to={ADMIN_PROFILE}>
                                                <div className="icon">
                                                    <i className="icon-account_circle"></i>
                                                </div>
                                                <span>Profile</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)">
                                                <div className="icon red">
                                                    <i className="icon-cog3"></i>
                                                </div>
                                                <p>Settings</p>
                                            </a>
                                        </li>
                                        <li>
                                            <Link to="/" onClick={handleLogout}>
                                                <div className="icon yellow">
                                                    <i className="icon-log-out"></i>
                                                </div>
                                                <p>Logout</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};
const mapDispatchToProps = (dispatch:any) => ({
    toggleSideMenu: () => dispatch({type: TOGGLE_SIDE_MENU})
})

export const NavBar = connect(null, mapDispatchToProps)(NavBarComponent);