import React from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Login.css';

import logo from '../../assets/images/admin-logo.png';
import { setUserSession } from 'helpers/CommonUtils';
import Footer from 'components/Footer';
import { ADMIN_DASHBOARD, ADMIN_SERVICE_PROVIDERS } from 'constants/RoutePaths';
// import { useJwt } from "react-jwt";
import jwt from "jwt-simple";

interface IFormInput {
    email: string;
    password: string;
}
const secret = 'secret';
export const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const history = useHistory();
    const onSubmit =(data: IFormInput) => {
        const encode = jwt.encode(data,secret);
        axios.post('/login', encode)
            .then((response) => {
                if(response.data.success === true){
                    toast.success('Log In Successfully!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                
                    setUserSession(response.data.token, response.data.userInfo)
                    history.push(ADMIN_DASHBOARD);
                }else{
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    }
            })
            .catch(_error => {
                console.log(_error);
            });
    };

    return(
        <React.Fragment>
            <div className="container">
                <div className="login-screen row align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <form className="form-align-middle" onSubmit = { handleSubmit(onSubmit)} >
                            <div className="login-container">
                                <div className="row no-gutters">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" className="login-logo">
                                                <img src={logo} alt="Spare Hands Admin Dashboard" />
                                            </a>
                                            <div className="input-group">
                                                <span className="input-group-addon" id="username">
                                                    <i className="icon-account_circle"></i>
                                                </span>
                                                <input type="text" className="form-control" placeholder="Email"
                                                        {...register("email", {
                                                            required: true,
                                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                        })} />
                                            </div>
                                            {errors.email && errors.email.type === "required" && (
                                                <span className="errorMsg mt-2" style={{ color: 'red' }}> 
                                                    * Email is required.
                                                </span>)}
                                            {errors.email && errors.email.type === "pattern" && (
                                                <span className="errorMsg mt-2" style={{ color: 'red' }}> 
                                                    * Email is not valid.
                                                </span>)}
                                            <br />
                                            <div className="input-group">
                                                <span className="input-group-addon" id="password">
                                                    <i className="icon-verified_user"></i>
                                                </span>
                                                <input type="password" 
                                                className="form-control" 
                                                placeholder="Password" 
                                                {...register("password", { required: true })}
                                                />
                                            </div>
                                            {errors.password && errors.password.type === "required" && (
                                                <span className="errorMsg" style={{ color: 'red' }}> 
                                                    * Password is required.
                                                </span>
                                                )}
                                            {errors.password && errors.password.type === "pattern" && (
                                                <span className="errorMsg" style = {{ color: 'red' }}> 
                                                    * Password is not valid.
                                                </span>
                                                )}
                                            <br />
                                            <div className="actions clearfix">
                                                <button type="submit" className="btn btn-primary">
                                                    <i className="icon icon-login"></i> Login
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                                        <div className="login-sliderImage"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <ToastContainer />
        </React.Fragment>
    );
};